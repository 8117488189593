* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  background-color: white;
  font-family: 'Roboto', sans-serif;
}


.ck-editor label {
  display: none !important;
}
